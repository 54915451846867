// src/MovieDetail.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './constants';
import ExpandableText from './components/ExpandableText';
import './MovieDetail.css';
import './layout.css'; // Import the new CSS file
import firebase from "firebase/compat/app"; // Import Firebase
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";




const MovieDetail = () => {
  const [movie, setMovie] = useState(null);
  const [playerError, setPlayerError] = useState(null);
  const [cleaningText1, setCleaningText1] = useState('');
  const [cleaningResponse1, setCleaningResponse1] = useState('');
  const [cleaningText2, setCleaningText2] = useState('');
  const [cleaningResponse2, setCleaningResponse2] = useState('');
  const [cleaningResponse3, setCleaningResponse3] = useState('');
  const playerRef = useRef(null);
  const { tmdbId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { movies, currentIndex } = location.state || { movies: [], currentIndex: -1 };
  const [isWatchLater, setIsWatchLater] = useState(false);


  function getStrategy(movie) {
    if (movie.like_count === null || movie.view_count === null) {
        return 3;
    }
    let choice = (movie.like_count + movie.view_count) % 10
    if (choice < 3)
        return 1
    else if (choice >= 3 && choice <= 6)
        return 2
    else
        return 3
  }

  function hexDecode(hex) {
    return hex.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
  }
  
  function deobfuscateYoutubeId(obfuscatedId, strategy) {
    let youtubeId;
    const decodedId = hexDecode(obfuscatedId);
  
    if (strategy === 1) {
      youtubeId = decodedId.slice(1, -1);;
    } else if (strategy === 2) {
      youtubeId = decodedId.slice(0, 1) + decodedId.slice(4);
    } else if (strategy === 3) {
      youtubeId = decodedId.slice(0, 3) + decodedId.slice(5);
    }
  
    return youtubeId;
  }

  useEffect(() => {
    const fetchMovieDetail = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/movie/${tmdbId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch movie details');
        }
        const data = await response.json();
        data.id = deobfuscateYoutubeId(data.id, getStrategy(data));
        if (data.poster_path) {
          if (!data.poster_path.startsWith('https')) {
            data.poster_path = `https://image.tmdb.org/t/p/w500${data.poster_path}`;
          }
        } else {
          data.poster_path = `https://via.placeholder.com/300x450?text=No+Poster`;
        }
        setMovie(data);
        firebase.analytics().logEvent('movie_detail', data.name);
      } catch (error) {
        console.error('Error fetching movie details:', error);
        setPlayerError('Failed to load movie details');
      }
    };

    fetchMovieDetail();
  }, [tmdbId]);

  useEffect(() => {
    if (movie) {
      // Clean up previous player instance
      if (playerRef.current) {
        playerRef.current.destroy();
      }

      const initializePlayer = () => {
        playerRef.current = new window.YT.Player(`youtube-player-${movie.name}`, {
          height: '360',
          width: '640',
          videoId: movie.id,
          playerVars: {
            controls: 1,
            modestbranding: 0,
            rel: 0,
            autoplay: 1,
            start: 1200, // Start at 20 minutes
            cc_load_policy: 0,
            origin: "http://cinemaneram.com/",
            showinfo: 0,
          },
          events: {
            onError: (event) => {
              console.error("Player error:", event.data);
              // commented out because it was causing the player to error out if movie before it was unavailable
              // setPlayerError(`Player error: ${event.data}`);

            },
          },
        });
      };

      if (window.YT && window.YT.Player) {
        initializePlayer();
      } else {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = initializePlayer;
      }

      const watchLaterMovies = JSON.parse(localStorage.getItem('watchLaterMovies') || '[]');
      setIsWatchLater(watchLaterMovies.includes(movie.tmdb_id));
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [movie]);

  // const toggleFullScreen = () => {
  //   const iframe = playerRef.current.getIframe();
  //   if (iframe) {
  //     if (iframe.requestFullscreen) {
  //       iframe.requestFullscreen();
  //     } else if (iframe.webkitRequestFullscreen) {
  //       iframe.webkitRequestFullscreen();
  //     } else if (iframe.mozRequestFullScreen) {
  //       iframe.mozRequestFullScreen();
  //     } else if (iframe.msRequestFullscreen) {
  //       iframe.msRequestFullscreen();
  //     }
  //   }
  // };


  const handleWatchLater = (movie) => {
    const movieIds = JSON.parse(localStorage.getItem('watchLaterMovies') || '[]');
    const index = movieIds.indexOf(movie.tmdb_id);
    if (index === -1) {
      movieIds.push(movie.tmdb_id);
      setIsWatchLater(true);
      showToast('Added to Watch Later');
    } else {
      movieIds.splice(index, 1);
      setIsWatchLater(false);
      showToast('Removed from Watch Later');
    }
    
    localStorage.setItem('watchLaterMovies', JSON.stringify(movieIds));
  };

  const handleBack = () => {
    firebase.analytics().logEvent('click_back');
    navigate('/');
  };

  const showToast = (message) => {
    const toast = document.createElement('div');
    toast.textContent = message;
    toast.style.position = 'fixed';
    toast.style.top = '0';
    toast.style.left = '50%';
    toast.style.transform = 'translateX(-50%)';
    toast.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
    toast.style.color = 'white';
    toast.style.padding = '10px';
    toast.style.borderRadius = '5px';
    document.body.appendChild(toast);
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 2000);
  };


  const handleNext = () => {
    firebase.analytics().logEvent('click_next_movie');
    if (currentIndex < movies.length - 1 && movies[currentIndex + 1]?.tmdb_id) {
      const nextMovie = movies[currentIndex + 1];
      navigate(`/movie/${nextMovie.tmdb_id}`, {
        state: { movies, currentIndex: currentIndex + 1 }
      });
    }
  };

  const handlePrevious = () => {
    firebase.analytics().logEvent('click_previous_movie');
    if (currentIndex > 0 && movies[currentIndex - 1]?.tmdb_id) {
      const prevMovie = movies[currentIndex - 1];
      navigate(`/movie/${prevMovie.tmdb_id}`, {
        state: { movies, currentIndex: currentIndex - 1 }
      });
    }
  };

  if (!movie) {
    return <div>Loading...</div>;
  }

  const groupCrewByJob = () => {
    if (!movie.crew) return {};

    const crewMap = movie.crew.reduce((acc, member) => {
      const job = member.job;
      acc[job] = (acc[job] || []).concat(member.name);
      return acc;
    }, {});

    // Sort job titles (optional):
    const sortedJobTitles = Object.keys(crewMap).sort((jobA, jobB) => {
      const jobOrder = { Director: 0, 'Director of Photography': 1, Writer: 2, Music: 3 };
      return jobOrder[jobA] - jobOrder[jobB];
    });

    return sortedJobTitles.reduce((acc, job) => {
      acc[job] = crewMap[job].join(', ');
      return acc;
    }, {});
  };

  const handleActorClick = (actorName) => {
    sessionStorage.removeItem('scrollPosition');
    // below is not working
    // localStorage.setItem('movieFilters', JSON.stringify({ actors: [], director: [], writer: [], year: [] }));
    
    // Navigate to the actor's page
    navigate(`/?actors=${actorName}`);
  };


  // cleaning stuff starts


  const handleCleaningTextChange1 = (e) => {
    setCleaningText1(e.target.value);
  };

  const handleCleaningSubmit1 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_tmdb_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: movie.id,
          tmdb_id: cleaningText1
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 1');
      }

      setCleaningResponse1(JSON.stringify(result, null, 2));
      setCleaningText1(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 1:', error);
      setCleaningResponse1(`Error: ${error.message}`);
    }
  };

  const handleCleaningTextChange2 = (e) => {
    setCleaningText2(e.target.value);
  };

  const handleCleaningSubmit2 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_yt_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tmdb_id: movie.tmdb_id,
          yt_id: cleaningText2
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 2');
      }

      setCleaningResponse2(JSON.stringify(result, null, 2));
      setCleaningText2(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 2:', error);
      setCleaningResponse2(`Error: ${error.message}`);
    }
  };

  const handleCleaningSubmit3 = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/movie/set_tmdb_and_yt_id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tmdb_id: cleaningText1,
          yt_id: cleaningText2
        }),
      });

      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to submit cleaning data 2');
      }

      setCleaningResponse3(JSON.stringify(result, null, 2));
      setCleaningText1(''); // Clear the input field
      setCleaningText2(''); // Clear the input field
    } catch (error) {
      console.error('Error submitting cleaning data 2:', error);
      setCleaningResponse3(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container movie-detail">
      <div className="row">
        <span onClick={handleBack} className="back-button2"><IoArrowBackOutline /></span>
        <span onClick={() => handleWatchLater(movie)}>
          {isWatchLater ? <FaBookmark /> : <FaRegBookmark />}
        </span>
      </div>
      <div className="video-container">
        {playerError ? (
          <div className="error-message">{playerError}</div>
        ) : (
          <>
            <div id={`youtube-player-${movie.name}`}></div>
            {/* <button onClick={toggleFullScreen} className="fullscreen-button">
              Fullscreen
            </button> */}
          </>
        )}
      </div>
      <div className="navigation-buttons">
        <button 
          onClick={handlePrevious} 
          disabled={currentIndex <= 0 || !movies[currentIndex - 1]} 
          className="nav-button prev-button"
        >
          Previous
        </button>
        <h3 className="movie-title">{movie.name}</h3>
        <button 
          onClick={handleNext} 
          disabled={currentIndex >= movies.length - 1 || !movies[currentIndex + 1]} 
          className="nav-button next-button"
        >
          Next
        </button>
        
      </div>
      <div className="movie-info">
        <img 
          src={movie.poster_path} 
          alt={movie.name} 
          className="movie-poster"
        />
        <div className="movie-details">
        <ExpandableText text={movie.plot} maxLength={150} />
        <p><strong>Genres:</strong> {movie.genres || 'Not available'}</p>
        <p><strong>Year:</strong> {movie.year || 'Not available'}</p>
        </div>
      </div>

      <div className="cast-crew-section">
        <h3>Cast</h3>
        <div>
          {movie.cast && movie.cast
            .slice()
            .sort((a, b) => a.order_number - b.order_number)
            .map((actor, index) => (
              <div key={index}>
                <span 
                  className="actor-link"
                  onClick={() => handleActorClick(actor.name)}
                >
                  {actor.name}
                </span>
                {actor.character ? ` as ${actor.character}` : ''}
              </div>
            ))}
        </div>
        <h3>Crew</h3>
        <div>
          {Object.entries(groupCrewByJob()).map(([job, crewNames], index) => (
            <div key={index}>
              {job}: {crewNames}
            </div>
          ))}
        </div>
      </div>

      {process.env.REACT_APP_ENV !== 'prod' && (  
        <div>       
          <div className="data-cleaning-section">
            <input 
              type="text" 
              value={cleaningText1} 
              onChange={handleCleaningTextChange1} 
              placeholder="Enter tmdb id"
            />
            <button onClick={handleCleaningSubmit1}>Submit</button>
            {cleaningResponse1 && (
              <pre className="cleaning-response">{cleaningResponse1}</pre>
            )}
          </div>
          <div className="data-cleaning-section">
            <input 
              type="text" 
              value={cleaningText2} 
              onChange={handleCleaningTextChange2} 
              placeholder="Enter yt id"
            />
            <button onClick={handleCleaningSubmit2}>Submit</button>
            {cleaningResponse2 && (
              <pre className="cleaning-response">{cleaningResponse2}</pre>
            )}
          </div>
          <div className="data-cleaning-section">
            <button onClick={handleCleaningSubmit3}>Submit new</button>
            {cleaningResponse3 && (
              <pre className="cleaning-response">{cleaningResponse3}</pre>
            )}
          </div>
        </div>
        
      )}

    </div>
    
  );
};

export default MovieDetail;