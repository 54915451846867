// src/FilterContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    const savedFilters = localStorage.getItem('movieFilters');
    return savedFilters ? JSON.parse(savedFilters) : { actors: [], director: [], writer: [], year: [] };
  });

  useEffect(() => {
    localStorage.setItem('movieFilters', JSON.stringify(filters));
  }, [filters]);

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => useContext(FilterContext);