// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MovieListingPage from './MovieListingPage';
import MovieDetail from './MovieDetail';
import WatchLaterPage from './WatchLaterPage';
import { FilterProvider } from './FilterContext';
import './layout.css';

// Import Firebase modules
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANEERblAoTG_cORTjQleGz9pEhdUg9sWs",
  authDomain: "malmov-48814.firebaseapp.com",
  projectId: "malmov-48814",
  storageBucket: "malmov-48814.appspot.com",
  messagingSenderId: "545655013745",
  appId: "1:545655013745:web:b71d47d973933e9b6a76d0",
  measurementId: "G-Y33EX46MTZ"
};
console.log('start')
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


// Initialize Analytics and get a reference to the service
firebase.analytics().logEvent('page_load'); 

const App = () => {
  const [cachedMovies, setCachedMovies] = useState([]);
  const [lastFilters, setLastFilters] = useState(null);

  return (
    <Router>
      <FilterProvider>
        <div className="container">
          <Routes>
            <Route 
              path="/" 
              element={
                <MovieListingPage 
                  cachedMovies={cachedMovies} 
                  setCachedMovies={setCachedMovies}
                  lastFilters={lastFilters}
                  setLastFilters={setLastFilters}
                />
              } 
            />
            <Route path="/movie/:tmdbId" element={<MovieDetail />} />
            <Route path="/later" element={<WatchLaterPage />} />
          </Routes>
        </div>
      </FilterProvider>
    </Router>
  );
};

export default App;