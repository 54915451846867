// src/MovieCard.js

import React from 'react';
import './MovieCard.css';

const MovieCard = ({ movie, onClick }) => {
  if (!movie) {
    return null; // or return a placeholder card
  }
  
  let posterUrl;
  if (movie.poster_path) {
    if (movie.poster_path.startsWith('https')) {
      posterUrl = movie.poster_path;
    } else {
      posterUrl = `https://image.tmdb.org/t/p/w500${movie.poster_path}`;
    }
  } else {
    posterUrl = `https://dummyimage.com/500x750/646464/ffffff&text=${movie.name}`;
  }


return (
  <div className="movie-card" onClick={onClick}>
    <div className="poster-container">
      <img src={posterUrl} alt={movie.name || 'Movie poster'} />
    </div>
    {/* <div className="movie-info">
      <h3>{movie.name || 'Untitled Movie'}</h3>
    </div> */}
  </div>
);
};

export default MovieCard;