import React, { useState } from 'react';
import './ExpandableText.css';

const ExpandableText = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <span className="text-content">{text}</span>;
  }

  const displayText = isExpanded ? text : text.slice(0, maxLength);

  return (
    <span className="expandable-text">
      <span className="text-content">
        {displayText}
        {!isExpanded && '... '}
      </span>
      <button
        onClick={toggleExpand}
        className="toggle-button"
      >
        {isExpanded ? 'Show Less' : 'Show More'}
      </button>
    </span>
  );
};

export default ExpandableText;