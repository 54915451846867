// src/MovieListingPage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Filters from './Filters';
import MovieGrid from './MovieGrid';
import SearchBar from './components/SearchBar';
import { FaRegBookmark } from "react-icons/fa";
import { Link } from 'react-router-dom';
import './MovieListingPage.css';
import { API_BASE_URL } from './constants';
import { useFilters } from './FilterContext';
import './layout.css'; // Import the new CSS file
import firebase from "firebase/compat/app"; // Import Firebase


const MovieListingPage = ({ cachedMovies, setCachedMovies, lastFilters, setLastFilters }) => {
  const [movies, setMovies] = useState(cachedMovies);
  const [loading, setLoading] = useState(cachedMovies.length === 0);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { filters, setFilters } = useFilters();
  const listRef = useRef(null);
  const location = useLocation();

  const setFiltersFromQueryParams = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const actor = queryParams.get('actors');
    const directors = queryParams.get('director');
    const writers = queryParams.get('writer');
    const year = queryParams.get('year');
    // Set filters based on query parameters
    if (actor) {
      const actorsArray = actor.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, actors: actorsArray }));
    }
    if (directors) {
      const arr = directors.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, director: arr }));
    }
    if (writers) {
      const arr = writers.split(',').map(a => a.trim());
      setFilters(prev => ({ ...prev, writer: arr }));
    }
    if (year) {
      setFilters(prev => ({ ...prev, year: [year] }));
    }
  }, [location.search, setFilters]); // Add dependencies
  
  useEffect(() => {
    setFiltersFromQueryParams(); // Set filters when the component mounts
  }, [setFiltersFromQueryParams]);

  const fetchMovies = useCallback(async () => {
    if (JSON.stringify(filters) === JSON.stringify(lastFilters)) {
      if (cachedMovies.length > 0) {
        setMovies(cachedMovies);
        setLoading(false);
        return;
      } else {
        // If cached movies are empty, don't make an API call
        setMovies([]);
        setLoading(false);
        
        setError('No movies found for the selected filters.');
        return;
      }
    }

    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams();
      if (filters.actors && filters.actors.length > 0) {
        queryParams.append('actors', filters.actors.join(','));
      }
      if (filters.director && filters.director.length > 0) {
        queryParams.append('director', filters.director.join(','));
      }
      if (filters.writer && filters.writer.length > 0) {
        queryParams.append('writer', filters.writer.join(','));
      }
      if (filters.year && filters.year.length > 0) {
        if (filters.year[0] === "Before 1980") {
          queryParams.append('end_year', 1980);
        }
        if (filters.year[0] === "1980-1990") {
          queryParams.append('start_year', 1980);
          queryParams.append('end_year', 1990);
        } 
        if (filters.year[0] === "1991-2000") {
          queryParams.append('start_year', 1991);
          queryParams.append('end_year', 2000);
        } 
        if (filters.year[0] === "2001-2010") {
          queryParams.append('start_year', 2001);
          queryParams.append('end_year', 2010);
        } 
        if (filters.year[0] === "After 2010") {
          queryParams.append('start_year', 2011);
        }
      }
      
      const queryString = queryParams.toString();
      const url = `${API_BASE_URL}/movies${queryString ? `?${queryString}` : ''}`;
      
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data && Array.isArray(data.movies)) {
        setMovies(data.movies);
        setCachedMovies(data.movies);
        setLastFilters(filters);
      } else {
        throw new Error('Invalid data structure received from API');
      }
    } catch (error) {
      setError('Failed to fetch movies. Please try again later.');
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, setCachedMovies, setLastFilters, lastFilters, cachedMovies]);

  useEffect(() => {
    fetchMovies();
  }, [fetchMovies]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition));
      }, 100);
      sessionStorage.removeItem('scrollPosition');
    }
  }, []);

  const handleMovieSelect = (movie, index) => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('scrollPosition', scrollPosition.toString());
    navigate(`/movie/${movie.tmdb_id}`, {
      state: { 
        movies: movies.map(m => ({ tmdb_id: m.tmdb_id })),
        currentIndex: index
      }
    });
  };

  const handlePlayRandomMovie = () => {
    if (movies.length > 0) {
      firebase.analytics().logEvent('click_play_random_movie');
      const randomIndex = Math.floor(Math.random() * movies.length);
      const randomMovie = movies[randomIndex];
      handleMovieSelect(randomMovie, randomIndex);
    }
  };

  const clearFilters = () => {
    setFilters({ actors: [], director: [], writer: [], year: [] });
    navigate('', { replace: true });
  };


  return (
    <div>
      <header className="app-header">
        <h1>Cinema Neram</h1>
        <div className="header-actions">
          <SearchBar />
          <Link to="/later" className="watch-later-link" aria-label="Watch Later">
            <FaRegBookmark />
          </Link>
        </div>
      </header>
      <div className="container">
        <div ref={listRef}>
          <Filters filters={filters} setFilters={setFilters} />
          <button onClick={handlePlayRandomMovie} className="random-movie-button">
            <span className="play-icon">▶</span> Play any movie
          </button>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <p>{error}</p>
              <button onClick={clearFilters} className="clear-filters-button">Clear Filters</button>
            </div>
          ) : (
            <MovieGrid movies={movies} onMovieSelect={handleMovieSelect} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MovieListingPage;