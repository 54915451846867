export const API_BASE_URL = (process.env.REACT_APP_ENV === "local") ? "http://localhost:8000": "https://cinemaneram.com/api"


export const DIRECTORS = [
    "P. Padmarajan",
    "Joshiy",
    "Priyadarshan",
    "Sathyan Anthikad",
    "Sibi Malayil",
    "Kamal",
    "I V Sasi",
    "Bharathan",
    "Siddique",
    "Govindan Aravindan",
    "Adoor Gopalakrishnan",
    "K. G. George",
    "T Hariharan",
    "Shaji Kailas",
    "K. Madhu",
    "Vinayan",
    "Rajasenan",
    "Jayaraj",
    "Fazil",
    "Bhadran",
    "Thulasidas",
    "Anil Kumar",
    "Babu Narayanan",
    "Sajan",
    "M. Krishnan Nair",
    "Balachandra Menon",
    "Viji Thamby",
    "P Bhaskaran",
    "Mohan",
    "Balu Kiriyath",
    "T S Suresh Babu",
    "K K Haridas",
    "Venu Nagavalli",
    "Thampi Kannanthanam",
    "Jose Thomas",
    "A. Vincent",
    "V M Vinu",
    "Raffi",
    "Mecartin",
    "Lal Jose",
    "Baby",
    "A. B. Raj",
    "A K Lohithadas",
    "V. K. Prakash",
    "Thaha",
    "T. K. Rajeev Kumar",
    "Ranjith",
    "Lal",
    "Jeassy",
    "Cochin Haneefa",
    "Sunil",
    "Shyamaprasad",
    "Sandhya Mohan",
    "Rosshan Andrrews",
    "Padmakumar M",
    "P.K. Joseph",
    "K G Rajasekharan",
    "Jomon",
    "George Kithu",
    "Crossbelt Mani",
    "Akku Akbar",
    "T. V. Chandran",
    "T. S. Mohan",
    "Shafi",
    "Sasi Shankar",
    "Ranjith Sankar",
    "Ramu Kariat",
    "Pramod",
    "Pappan",
    "P G Viswambharan",
    "P. Venu",
    "P. Chandrakumar",
    "P. Anil",
    "P Subramaniam",
    "P N Menon",
    "Nissar",
    "N Sankaran Nair",
    "Mohan Kupleri",
    "Mani Ratnam",
    "M. Mani",
    "Lenin Rajendran",
    "Kaladharan Adoor",
    "Jeethu Joseph",
    "Harikumar",
    "Haridas",
    "G. S. Vijayan",
    "B. Unnikrishnan",
    "Vysakh",
    "Vijayanand",
    "Venugopan",
    "V. Ashok Kumar",
    "Thoppil Bhasi",
    "T S Saji",
    "Suresh Vinu",
    "Shajoon Kariyal",
    "J Sasikumar",
    "Sangeeth Sivan",
    "Sameer Thahir",
    "Ramesh Varma",
    "Prathap Pothan",
    "P K Baburaj",
    "Major Ravi",
    "Lijo Jose Pellissery",
    "Kunchacko",
    "K. B. Madhu",
    "K Sreekuttan",
    "Joshi Mathew",
    "K. S. Sethumadhavan",
    "John Abraham",
    "Jijo Antony",
    "J. Williams",
    "Dennis Joseph",
    "Boban Samuel",
    "Biju Varkey",
    "Ashok R Nath",
    "Anwar Rasheed",
    "Anjali Menon",
    "Amal Neerad",
    "Alleppey Ashraf",
    "Ali Akbar",
    "Yousufali Kecheri",
    "Vipin Das",
    "Vinod Mankara",
    "Vijayan K",
    "Venkat Prabhu",
    "Vasudev Sanal",
    "V.R. Gopalakrishnan",
    "Tinu Pappachan",
    "Suresh Unnithan",
    "Sudin Menon",
    "Sreenivasan",
    "Shaji Azeez",
    "Sekhar Kammula",
    "Saji Surendran",
    "S.L Puram Jayasurya",
    "S S Rajan",
    "S A Salam",
    "Renji Panicker",
    "Rajesh Pillai",
    "Rajesh Nair",
    "Rajeev Ravi",
    "Rajeev Nath",
    "Rajan Sankaradi",
    "R. Sukumaran",
    "R Sarath",
    "Puri Jagannadh",
    "Pappan Naripatta",
    "P. N. Sundaram",
    "P. A. Backer",
    "P K Radhakrishnan",
    "N. P. Suresh",
    "Martin Prakkat",
    "Mahesh",
    "Madhupal",
    "M. Mohanan",
    "M T Vasudevan Nair",
    "Kottayam Chellappan",
    "Khalid Rahman",
    "K. Biju",
    "Jeo Baby",
    "Haridas Kesavan",
    "Gokul Ramakrishnan",
    "G. Premkumar",
    "G. Marthandan",
    "Blessy",
    "Bijukumar Damodaran",
    "Arun Vaiga",
    "Arun Kumar Aravind",
    "Anvar Sadik",
    "Antony Eastman",
    "Aby Jose",
    "Aashiq Abu",
    "A. L. Vijay"
]

export const ACTORS = [
    "Mammootty",
    "Mohanlal",
    "Prem Nazir",
    "Jayaram",
    "Mukesh",
    "Suresh Gopi",
    "Urvashi",
    "Shobana",
    "Dileep",
    "Madhu",
    "Jagadish",
    "Nedumudi Venu",
    "Sreenivasan",
    "Jagathy Sreekumar",
    "Kunchacko Boban",
    "Siddique",
    "Prithviraj Sukumaran",
    "Kalabhavan Mani",
    "Sathyan",
    "Innocent",
    "Thilakan",
    "Seema",
    "Sukumaran",
    "Jayan",
    "Murali",
    "Jayabharathi",
    "Adoor Bhasi",
    "Shankar",
    "Sheela",
    "Biju Menon",
    "Sharada",
    "Indrajith Sukumaran",
    "Ratheesh",
    "Rahman",
    "Jayasurya",
    "Srividya",
    "Manoj K Jayan",
    "Geetha",
    "Balachandra Menon",
    "Vijayaraghavan",
    "Kavya Madhavan",
    "Meena",
    "Manju Warrier",
    "Vani Viswanath",
    "Sumalatha",
    "Suhasini Maniratnam",
    "Menaka Suresh",
    "Kamal Haasan",
    "Dulquer Salmaan",
    "Divyaa Unni",
    "Prem Kumar",
    "Parvathi",
    "Mamta Mohandas",
    "Lissy",
    "Gopi",
    "Fahadh Faasil",
    "Babu Antony",
    "Ashokan",
    "Vineeth Radhakrishnan",
    "Shalini",
    "Saikumar",
    "M G Soman",
    "Lal",
    "Karthika",
    "Thikkurissy Sukumaran Nair",
    "Sridevi",
    "Revathi",
    "Nivin Pauly",
    "Navya Nair",
    "Maniyanpilla Raju",
    "Madhavi",
    "Lakshmi",
    "Annie",
    "Ambika",
    "Zarina Wahab",
    "Vijay",
    "Sunitha",
    "Shanthi Krishna",
    "Samyuktha Varma",
    "Rekha",
    "Ranjini",
    "Nayanthara",
    "Mohini",
    "Meera Jasmine",
    "Kaviyoor Ponnamma",
    "Indrans",
    "Asif Ali",
    "Anoop Menon",
]

export const WRITERS = [
    "Sreenivasan",
    "A K Lohithadas",
    "Thoppil Bhasi",
    "M T Vasudevan Nair",
    "Dennis Joseph",
    "Benny P. Nayarambalam",
    "Kaloor Dennis",
    "Renji Panicker",
    "P. Padmarajan",
    "Bobby",
    "Sanjay",
    "Ranjith",
    "S. N. Swamy",
    "T Damodaran",   
    "John Paul",
    "Priyadarshan",
    "Uday Krishna",
    "Siby K Thomas",
    "Raffi",
    "Mecartin",
    "Sasidharan Arattuvazhi",
    "Fazil",
    "Siddique",
    "Raghunath Paleri",
    "Joy Pallassery",
    "Balachandra Menon",
    "Vinu Kiriyath",
    "Rajan Kiriyath",
    "V. C. Ashok",
    "Pappanamkodu Lakshmanan",
    "Babu Janardhanan",
    "A K Sajan",
    "T. A. Razzaq",
    "Cochin Haneefa",
    "Vinayan",
    "Robin Thirumala",
    "Lal",
    "Venu Nagavalli",
    "Kalavoor Ravikumar",
    "A. Sheriff",
]