// src/components/SearchBar.js

import React, { useState, useRef, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../constants';
import './SearchBar.css';

const SearchBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isExpanded) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 1) {
        fetchSearchResults(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSearchResults = async (query) => {
    try {
      const response = await fetch(`${API_BASE_URL}/movies/search?query=${encodeURIComponent(query)}`);
      if (!response.ok) {
        throw new Error('Search request failed');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    }
  };

  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setSearchTerm('');
      setSearchResults([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleResultClick = (id) => {
    navigate(`/movie/${id}`);
    setIsExpanded(false);
    setSearchTerm('');
    setSearchResults([]);
  };

  return (
    <div className={`search-bar ${isExpanded ? 'expanded' : ''}`}>
      <form onSubmit={(e) => e.preventDefault()}>
        {isExpanded && (
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search movies..."
          />
        )}
        <button type="button" onClick={handleSearchClick} className="search-icon">
          <FaSearch />
        </button>
      </form>
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map(([title, id]) => (
            <li key={id} onClick={() => handleResultClick(id)}>
              {title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;