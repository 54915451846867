// src/Filters.js

import React from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { ACTORS, DIRECTORS, WRITERS } from './constants';
import './Filters.css';
import { useFilters } from './FilterContext';

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#121212', // Dark background for the input control
    color: '#fff',           // Text color
    borderColor: 'grey',
    fontSize: '16px'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#333', // Dark background for the dropdown menu
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#555' : '#333', // Highlight the option when focused
    color: '#fff',  // Text color
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#555',
  }),
};

const Filters = () => {
  const { filters, setFilters } = useFilters();
  const navigate = useNavigate();

  const updateUrl = (newFilters) => {
    const searchParams = new URLSearchParams();
    if (newFilters.actors.length) searchParams.set('actors', newFilters.actors.join(','));
    if (newFilters.director.length) searchParams.set('director', newFilters.director.join(','));
    if (newFilters.writer.length) searchParams.set('writer', newFilters.writer.join(','));
    if (newFilters.year.length) searchParams.set('year', newFilters.year.join(','));
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleActorChange = (selectedOptions) => {
    const newFilters = {
      ...filters,
      actors: selectedOptions ? selectedOptions.map(option => option.value) : []
    };
    setFilters(newFilters);
    updateUrl(newFilters);
  };

  const handleDirectorChange = (selectedOptions) => {
    const newFilters = {
      ...filters,
      director: selectedOptions ? selectedOptions.map(option => option.value) : []
    };
    setFilters(newFilters);
    updateUrl(newFilters);
  };

  const handleWriterChange = (selectedOptions) => {
    const newFilters = {
      ...filters,
      writer: selectedOptions ? selectedOptions.map(option => option.value) : []
    };
    setFilters(newFilters);
    updateUrl(newFilters);
  };

  const handleYearChange = (selectedOptions) => {
    let newFilters;
    if (selectedOptions.length === 0) {
      newFilters = {
        ...filters,
        year: []
      };
    }
    else {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      newFilters = {
        ...filters,
        year: [lastOption.value]
      };
    }
    setFilters(newFilters);
    updateUrl(newFilters);
  };

  // const clearFilters = () => {
  //   setFilters({ actors: [], director: [] });
  //   navigate('', { replace: true });
  // };

  return (
    <div className="filters">
      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="actors"
          options={ACTORS.map(actor => ({ value: actor, label: actor }))}
          value={filters.actors.map(actor => ({ value: actor, label: actor }))}
          onChange={handleActorChange}
          placeholder="Select actors..."
        />
      </div>
      
      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="director"
          options={DIRECTORS.map(director => ({ value: director, label: director }))}
          value={filters.director.map(director => ({ value: director, label: director }))}
          onChange={handleDirectorChange}
          placeholder="Select director..."
        />
      </div>

      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="writer"
          options={WRITERS.map(writer => ({ value: writer, label: writer }))}
          value={filters.writer.map(writer => ({ value: writer, label: writer }))}
          onChange={handleWriterChange}
          placeholder="Select writer..."
        />
      </div>

      <div className="select-container">
        <Select styles={customStyles}
          isMulti
          name="year"
          options={[
            { value: "Before 1980", label: "Before 1980" },
            { value: "1980-1990", label: "1980-1990" },
            { value: "1991-2000", label: "1991-2000" },
            { value: "2001-2010", label: "2001-2010" },
            { value: "After 2010", label: "After 2010" }
          ]}
          value={filters.year.map(year => ({ value: year, label: year }))}
          onChange={handleYearChange}
          placeholder="Select year..."
        />
      </div>
      
      {/* <button onClick={clearFilters} className="clear-button">Clear Filters</button> */}
    </div>
  );
};

export default Filters;