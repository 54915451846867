// src/MovieGrid.js

import React from 'react';
import MovieCard from './MovieCard';
import './MovieGrid.css';

const MovieGrid = ({ movies, onMovieSelect }) => {
  if (!movies || movies.length === 0) {
    return <div>No movies available</div>;
  }

  return (
    <div className="movie-grid">
      {movies.map((movie, index) => (
        <MovieCard 
          key={movie.id} 
          movie={movie} 
          onClick={() => onMovieSelect(movie, index)} 
        />
      ))}
    </div>
  );
};

export default MovieGrid;